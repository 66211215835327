export default {
  data() {
    return {
      texts: "",
      title: "",
      descripcion: "",
      note: "",
      chkLabel: "",
      api: "",
      returnMain: false,
      dobleConfirm: false,
      chkGlobalDialog: false,
      params: {},
      sAction: "",
      bLoading: false,
      screenWidth: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.globalDialogTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 55;
      } else {
        this.screenWidth = 100;
      }
    },
    close() {
      this.$store.commit("setGlobalDialog", {
        active: false,
        title: "",
        descipcion: "",
        api: "",
      });

      // this.$store.commit("setDialogDetailsCustomerWareHouse",
      // {active: false,
      // arr : []});

      // this.$store.commit("setdialogGlobalWarehouse", {
      //   active: false,
      //   arr: [],
      // apidetail:'',
      // apidocument:''
      // });

      this.clearForm();
    },
    clearForm() {
      this.title = "";
      (this.descipcion = ""), (this.api = "");
    },
    actionGlobalItem() {
      this.bLoading = true;
      DB.patch(`${this.api}`, this.params, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.bLoading = false;
          this.close();
          this.mixSuccess(response.data.message);
          if (this.returnMain) {
            this.$router.go(-1);
          } else {
            this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          this.bLoading = false;
          this.close();
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  watch: {
    dialogGlobalDialog() {
      if (this.dialogGlobalDialog) {
        this.$store.commit("setMenuSidebar", { active: false });

        this.title = this.$store.state.titleGlobalDialog;
        this.descripcion = this.$store.state.descripcionGlobalDialog;
        this.note = this.$store.state.noteGlobalDialog;
        this.chkLabel = this.$store.state.chkLabelGlobalDialog;
        this.api = this.$store.state.apiGlobalDialog;
        this.params = this.$store.state.params;
        this.sAction = this.$store.state.sAction;
        this.returnMain = this.$store.state.returnMain;
        this.dobleConfirm = this.$store.state.dobleConfirm;
        !this.dobleConfirm
          ? (this.chkGlobalDialog = true)
          : (this.chkGlobalDialog = false);
        this.texts = FILE.globalDialogTexts[this.selectLanguage];
      }
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.globalDialogTexts[this.selectLanguage];
      }
    },
  },
  computed: {
    dialogGlobalDialog() {
      return this.$store.state.dialogGlobalDialog;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
};
